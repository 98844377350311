.login-form-wrap {
  padding: 40px 40px;

  .logo {
    width: 250px;
  }

  .language-switch {
    margin: auto;
    text-align: center;

    .language-label {
      color: #000;
    }

    .MuiSwitch-track {
      background-color: #000;
    }

    .MuiSwitch-thumb {
      background-color: #000;
    }
  }

  .login-text {
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 20px;
  }

  .login-input {
    margin: 20px 10px 0 0;
    .MuiInputBase-root {
      border-radius: 25px;
    }
  }

  .login-input {
    .MuiInputLabel-root {
      font-size: 12px !important;
      opacity: 0.57;
      text-align: center;
      text-transform: inherit;
      margin-top: 2px;
    }
  }

  .login-logo {
    margin: 90px auto 50px auto;
    height: 120px;
    max-width: 100%;
    display: block;
  }

  .login-button {
    margin-top: 20px;
    background-color: #2c92f8;
    border-radius: 25px;
    text-transform: capitalize;

    .MuiButton-label {
      font-size: 16px;
      font-weight: bold;
      color: white;
    }
  }

  .login-button:hover {
    background-color: #000000;
  }

  .MuiFormControl-root {
    width: 100%;
  }

  .login-checkbox {
    margin-top: 5px;

    .login-checkbox-label {
      font-weight: 400;
    }

    .Mui-checked {
      .MuiSvgIcon-root > path {
        color: #2c92f8;
      }
    }

  }

  .forgot-link {
    margin-top: 20px;
    display: block;
    font-size: 14px;
    color: #000000;
  }

  .customer-label {
    font-size: 20px;
    margin-top: 30px;
    margin-bottom: 5px;
  }
  
  .test-now-label {
    font-size: 20px;
    color: #2c92f8;
    margin-top: 10px;
    font-weight: bold;
  }

  @media screen and (max-width: 1440px) {
    .login-checkbox {
      .login-checkbox-label {
        font-size: 0.875rem;
      }
    }

    .login-input {
      .MuiInputLabel-root,
      .MuiInputBase-input {
        font-size: 0.875rem;
      }
    }

    .login-button {
      font-size: 0.625rem;
    }

    .forgot-link {
      font-size: 0.875rem;
      color: #000000;
    }
  }

  @media screen and (max-width: 1024px) {
    .login-logo {
      margin: 60px auto 40px auto;
    }
  }
}


@media (max-width: 750px) {
  .login-form-wrap {
    height: 100vh;

    .customer-label {
      margin-bottom: 20px;
    }
  }
}

