.box-wrap {
  padding: 20px;
  border-radius: 10px !important;
  box-shadow: none !important;
  border: 1px solid #EAEAEA !important;

  .MuiTablePagination-root:last-child {
    border-bottom: none;
  }

  .box-toolbar {
    margin-bottom: 30px;
  }
}
