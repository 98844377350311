.intro-card {
  .intro-container {
    align-self: center;
  }

  .content-section {
    background: #fff;
    position: relative;
    border-radius: 15px;
  }

  .intro-intro {
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 1;

    &::before {
      content: ' ';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: url('/images/Captaincoupon_Login_Admin.jpg');
      background-position: 100% 100%;
      background-size: cover;
      background-repeat: no-repeat;
      z-index: -1;
      opacity: 0.5;
    }

    .intro-message {
      align-self: center;
      padding: 120px 60px 50px 60px;
      color: #fff;
      flex: 2;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .tagline {
      font-weight: 300;
      margin-top: 20px;
    }

    .official-url {
      flex: 0;
      text-align: center;
      padding: 20px 40px;
      margin-bottom: 50px;

      a {
        color: #fff;
        font-weight: 300;
        text-decoration: none;
        font-size: 14px;
      }
    }
  }

  .login-background {
    width: 65%;
  }

  .intro-paper.MuiPaper-root {
    background: #2e86ab;
  }

  .intro-form {
    background: #fff;
  }

  @media screen and (max-width: 1440px) {
    .intro-container {
      .intro-intro {
        .intro-message {
          .intro-title {
            font-size: 3rem;
          }
        }
      }
    }
  }

  @media screen and (max-width: 1279px) {
    .intro-container {
      display: flex;
      flex-direction: column-reverse;
    }

    .intro-intro {
      .intro-message {
        padding: 100px 60px 50px 60px;
      }
    }

  .login-background {
      display: none;
    }
  }

  @media (max-width: 750px) {
    .content-section {
      border-radius: 0px;
    }
  }
}