.info-button {
  height: 24px;
  width: 24px;
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.margin-left-10 {
  margin-left: 10px !important;
}

.info-margin-left-auto {
  margin-left: auto !important;
}
