.admin {
  .content {
    position: absolute;
    top: 100px;
    left: 88px;
    width: calc(100% - 88px);
    padding: 30px;
    background: #fafafa;
    box-sizing: border-box;

    &.content--open {
      top: 100px;
      left: 315px;
      width: calc(100% - 315px);
    }

    @media print {
      top: 0 !important;
      left: 0 !important;
      padding: 0 !important;
      width: 100% !important;
    }

    @media only screen and (max-width: 768px) {
      left: 0;
      width: 100%;
    }
  }

  .padding-0 {
    padding: 0 !important;
  }

  @media only screen and (max-width: 768px) {
    .content {
      width: 100%;
      left: 0;

      &.content--open {
        width: 100%;
        left: 0;
      }
    }
  }

  @media screen and (max-width: 1440px) {
    .content {
      top: 65px;

      &.content--open {
        top: 65px;
        h4 {
          font-size: 24px;
        }
      }
    }
  }
}
