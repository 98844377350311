.page-title {
  margin-bottom: 10px;

  h1 {
    font-weight: 400;
    font-size: 24px;
    display: inline;
    &.secondaryTitle {
      color: #197bbd;
      margin-left: 20px;
    }
  }

  > div {
    display: flex;
    align-items: center;
  }

  .page-title__options {
    text-align: right;
    justify-content: end;
  }
}
