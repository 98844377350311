.btn-profile.MuiButton-text {
  background: transparent;
  letter-spacing: 1px;
  padding: 10px;
}

.profile-wrap {
  text-align: right;

  .profile-name {
    font-weight: bold;
    font-size: 13px;
  }

  .profile-type {
    text-transform: capitalize;
    font-weight: 300;
    opacity: 0.8;
    line-height: 13px;
    font-size: 13px;
  }
}

.arrow {
  align-self: flex-start;
  margin-left: 15px;
  margin-top: 4px;

  svg {
    width: 15px;
  }
}

.user-dropdown {
  .MuiPaper-root {
    border-radius: 0 0 15px 15px;
    background-color: #000;
    box-shadow: none;
    min-width: 150px;

    .MuiMenuItem-gutters {
      color: #fff;
      text-transform: uppercase;
      font-size: 11px;
      font-weight: 500;
      text-align: center;
      letter-spacing: 1px;
      justify-content: center;
      padding: 0;

      &:hover {
        background: rgba(255, 255, 255, 0.1);
      }
    }

    .MuiListItem-button {
      a {
        padding: 15px 16px;
      }
    }
  }
}
