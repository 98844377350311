.set-password-form-wrap {
  padding: 50px 40px;

  .logo {
    width: 250px;
  }

  .language-switch {
    margin: auto;
    text-align: center;

    .language-label {
      color: #000;
    }

    .MuiSwitch-track {
      background-color: #000;
    }

    .MuiSwitch-thumb {
      background-color: #000;
    }
  }

  .grower {
    margin-top: 162px;
  }

  .set-password-input {
    margin: 20px 10px 0 0;
    .MuiInputBase-root {
      border-radius: 25px;
    }
  }

  .set-password-input {
    .MuiInputLabel-root {
      font-size: 12px !important;
      opacity: 0.57;
      text-align: center;
      margin-top: 2px;
    }
  }

  .set-password-logo {
    margin: 90px auto 50px auto;
    width: 300px;
    display: block;
  }

  .set-password-button {
    margin-top: 20px;
    background-color: #2c92f8;
    border-radius: 25px;

    .MuiButton-label {
      font-size: 16px;
      font-weight: bold;
      color: white;
    }
  }

  .set-password-button:hover {
    background-color: #000000;
  }

  .MuiFormControl-root {
    width: 100%;
  }

  .set-password-checkbox {
    margin-top: 5px;

    .set-password-checkbox-label {
      font-weight: 400;
    }
  }

  .forgot-link {
    margin-top: 20px;
    display: block;
  }

  @media screen and (max-width: 1440px) {
    .set-password-checkbox {
      .set-password-checkbox-label {
        font-size: 0.875rem;
      }
    }

    .set-password-input {
      .MuiInputLabel-root,
      .MuiInputBase-input {
        font-size: 0.875rem;
      }
    }

    .set-password-button {
      font-size: 0.625rem;
    }

    .forgot-link {
      font-size: 0.875rem;
    }
  }

  @media screen and (max-width: 1024px) {
    .set-password-logo {
      margin: 60px auto 40px auto;
    }
  }

  .set-success {
    text-align: center;
    margin-top: 105px;
    margin-bottom: 105px;
    display: block;

    img {
      margin: 65px auto;
      width: 100px;
      display: block;
    }
  }

  .set-pass-link {
    margin-top: 20px;
    display: block;
    font-size: 14px;
    color: #000000;
  }
}

@media (max-width: 767px) {
  .set-password-form-wrap {
    height: 100vh;
  }

  .customer-label {
    margin-bottom: 20px;
  }
}
