@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');

body {
  font-family: 'Montserrat', sans-serif;
  padding: 0;
  margin: 0;
  background: #fafafa;
}

$primaryColor: #197bbd;

p {
  line-height: 24px;
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.back-button {
  margin-top: 15px;
  display: block;
}

.pagination {
  flex-shrink: 0;
}

// .MuiButton-root.submit-button {
//   margin-top: 30px;
// }

.ql-editor {
  word-break: break-word;
}

.color-black {
  > .Mui-disabled {
    color: #000 !important;
  }
}

.page-title {
  margin-top: 0;
  .title-cell {
    .export-button {
      &.MuiButton-text {
        letter-spacing: unset;
        font-size: 14px;
        font-weight: 400;
        text-transform: unset;
        background: transparent;
      }

      img {
        margin-right: 10px;
      }
    }

    .upload-button {
      margin-left: 20px;
    }
  }
}

@page {
  size: landscape;
}

.no-results {
  margin: 200px auto;
  width: 300px;
  text-align: center;

  h2 {
    color: #bababa;
    text-align: center;
    font-size: 30px;
  }

  &.no-results--small {
    margin: 100px auto;

    h2 {
      font-size: 14px;
      color: #bababa;
      text-align: center;
    }
  }
}

.chrome-picker {
  position: absolute !important;
  z-index: 1;
}

.info-document {
  text-align: center;
  padding: 15px;

  img {
    padding-bottom: 10px;
  }
}

.secondary-label {
  color: #00000078;
  margin-top: auto;
  margin-bottom: auto;
}

.pl-1 {
  padding-left: 10px;
}

.no-updates-banner {
  height: 90px;
  background-color: #3699fb;
  color: #fff;
  padding: 0px 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;

  h3 {
    display: inline;
    font-weight: 500;
  }

  .clickhere-button {
    height: 20px;
    padding: 20px 30px;
    font-size: 9px;
  }
}

.document-container {
  margin-top: 20px;

  .document-name {
    font-weight: 400;
    font-size: 14px;
    line-height: 1.4rem;
    text-overflow: ellipsis;
    height: 30px;
    overflow: hidden;
    white-space: nowrap;
  }
}

.document-container-small {
  .document-button {
    display: block;
    background: none;
    width: 100%;
    justify-content: flex-start;

    &:hover {
      background-color: #e9e9e9;
    }

    .MuiButton-label {
      display: flex;

      .document-name {
        display: inline;
        font-weight: 400;
        font-size: 14px;
        letter-spacing: normal;
        text-transform: none;
        margin-left: 5px;
      }
    }
  }
}

.bottom-toolbar {
  margin-top: 20px;
  display: flex;
  flex-direction: row;

  .grow {
    flex-grow: 1;
  }

  .per-page {
    padding-top: 10px;

    label {
      margin-right: 10px;
      font-size: 14px;
    }

    .MuiSelect-icon {
      top: calc(50% - 16px);
    }

    .MuiSelect-selectMenu {
      padding-bottom: 0;
    }
  }
}

// Global Mui overrides
.MuiPickersToolbarButton-toolbarBtn.MuiButton-text {
  background: transparent;
}

.MuiSelect-root.MuiSelect-select:focus {
  background-color: transparent;
}

.MuiAutocomplete-popper {
  z-index: 0 !important;
}

.display-none {
  display: none !important;
}

.mt-20 {
  margin-top: 20px;
}

@for $i from 0 to 10 {
  .mt-#{$i} {
    margin-top: $i * 10px !important;
  }
  .mb-#{$i} {
    margin-bottom: $i * 10px !important;
  }

  .pt-#{$i} {
    padding-top: $i * 10px !important;
  }

  .pb-#{$i} {
    padding-bottom: $i * 10px !important;
  }

  .p-#{$i} {
    padding: $i * 10px !important;
  }
}

.read-only-text-item {
  margin-top: 20px;
  margin-bottom: 20px;
}

.MuiButton-text.btn-cancel {
  display: inline-block;
  // margin: 30px 10px 0;
}

@media screen and (max-width: 1440px) {
  .content {
    h4 {
      font-size: 1.5rem;
    }

    &.content--open {
      top: 65px;
      h4 {
        font-size: 1.5rem;
      }
    }

    .MuiFormLabel-root {
      font-size: 0.875rem;
    }

    input.MuiInputBase-input {
      font-size: 0.75rem;
      height: 0.75rem;
    }

    .MuiTable-root {
      .MuiTableCell-root {
        font-size: 0.75rem;
      }
    }

    .bottom-toolbar {
      .per-page {
        padding-top: 10px;

        label {
          margin-right: 10px;
          font-size: 0.75rem;
        }

        .MuiSelect-icon {
          top: calc(50% - 10px);
        }

        .MuiSelect-selectMenu {
          padding-bottom: 8px;
          font-size: 0.75rem;
        }
      }
    }
  }
}

fieldset {
  border: 1px solid #ccc;
  padding: 15px 25px 15px;
  margin: 20px 0;
  legend {
    background-color: #fff;
    padding: 0 10px;
  }
}

.box-wrap + .box-wrap {
  margin-top: 30px;
}

.MuiFormControl-root.pad-tb-3 {
  padding-top: 30px;
  padding-bottom: 30px;
}
.pad-b-3 {
  padding-bottom: 30px;
}
.pad-tb-3,
.MuiDialogContent-dividers.pad-tb-3 {
  padding-bottom: 30px;
  padding-top: 30px;
}
.centeredGridCell {
  display: flex;
  align-items: center;
  justify-content: center;
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  margin-top: 20px;
}

.upload-image-label-grid {
  margin-top: 30px;
  margin-bottom: 10px;
}

// Utility classes
.d-inline-flex {
  display: inline-flex;
}

.upload-chip {
  background-color: #008BFF !important;
  border-radius: 30px !important;
  height: 37px !important;
  color: #FFFFFF !important;
}

.upload-chip-disabled {
  background-color: #008BFF !important;
  border-radius: 30px !important;
  height: 37px !important;
  color: #FFFFFF !important;
  opacity: 0.5;
}

.rounded-select-20 {
  margin: 10px 0 0 0;
  height: 33px !important;
  border-radius: 20px !important;
}

.rounded-select-10 {
  margin: 10px 0 0 0;
  height: 33px !important;
  border-radius: 10px !important;
}

.rounded-input-20 {
  margin: 10px 0 0 0;
  .MuiInputBase-root {
    border-radius: 20px;
    margin-top: 5px;
    height: 32px;
  }
}

.rounded-input-multiline {
  margin: 10px 0 0 0;
  .MuiInputBase-root {
    border-radius: 20px;
    margin-top: 5px;
  }
}

.rounded-input-10 {
  margin: 10px 0 0 0;
  .MuiInputBase-root {
    border-radius: 10px;
  }
}

.pt-15px {
  padding-top: 15px;
}

.svg-sidebar-icon {
  height: 1.5rem;
  width: 3rem;
  fill: #9F9E9E;
  margin-right: 15px;
}

.item--selected > div > .svg-sidebar-icon {
  fill: #2c92f8;
}

.primary-button {
  border-radius: 25px !important;
  text-transform: uppercase !important;
}

.primary-button:hover {
  background-color: #000000 !important;
}

.button {
  border-radius: 15px;
}

.submit-button {
  border-radius: 25px;
  height: 37px;
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.cancel-button {
  border-radius: 25px !important;
  height: 37px;
  padding-right: 3rem !important;
  padding-left: 3rem !important;
  background: white !important;
  border: 1px solid #BFBFBF !important;
  color: #2c92f8 !important;
}

.checkbox-icon {
  color: #008BFF;
}

.delete-icon {
  color: #9D9D9D !important;
}

.h-37 {
  height: 37px;
}

.rounded-card {
  .MuiPaper-root {
    border-radius: 10px;
    box-shadow: none !important;
    border: 1px solid #EAEAEA;
  }
}

.black-button {
  border-radius: 25px !important;
  background: #000000 !important;
  color: #FFFFFFDE !important;
  border: 1px solid #000000 !important;
}

.black-button:hover {
  background-color: #FFFFFFDE !important;
  color: #000000 !important;
  border: 1px solid #000000 !important;
}

.date-picker-dialog {
  .MuiDialogActions-root {
    padding: 20px;
  }
  .MuiDialogActions-root > button:nth-child(1) {
    /* Styles for the Clear button */
    padding: 10px 20px !important;
  }
  
  .MuiDialogActions-root > button:nth-child(2) {
    /* Styles for the Cancel button */
    padding: 10px 20px !important;
    background: #000000 !important;
    color: #FFFFFFDE !important;
    border: 1px solid #000000 !important;
  }
  
  .MuiDialogActions-root > button:nth-child(3) {
    /* Styles for the OK button */
    padding: 10px 20px !important;
    color: #fff;
    background-color: #2c92f8;

    &:hover {
      background-color: #000000 !important;
    }
  }
}

@media screen and (max-width: 1440px) {
  .content .MuiFormLabel-root {
    font-size: 1rem;
  }
}