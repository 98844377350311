.coupon-header {
  z-index: 1201 !important; // one more than info and blocked modal
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  
  &.coupon-header--closed {
    .brand {
      width: 43px;
      padding: 0 20px 0 0;
    }
  }

  background-color: #ffffff !important;

  &.MuiAppBar-colorDefault {
    &::before {
      content: ' ';
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
      background-image: url('/images/background.jpg');
      background-repeat: no-repeat;
      background-position: 100% 17%;
      opacity: 0.5;
    }
  }

  .brand {
    top: 0;
    left: 0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    width: 266px;
    padding: 0px 24px 0px 0px;
    transition: all 0.2s;

    a {
      justify-content: center;
      display: flex;
    }

    .brand-logo {
      max-width: 70%;
      height: 100%;
      width: 200px;
    }
  }

  .grow {
    flex-grow: 1;
  }

  .btn-menu {
    padding: 20px;
    margin-left: 13px;
  }

  .nav-toggle {
    width: 25px;
  }

  @media print {
    display: none !important;
  }

  @media screen and (max-width: 1440px) {
    max-height: 65px;
    .profile-wrap {
      .profile-name {
        font-size: 0.75rem;
      }
      .profile-type {
        font-size: 0.75rem;
      }
    }
    .brand {
      .brand-logo {
        height: 30px;
        width: auto;
        max-width: 100%;
      }
    }
  }

  @media only screen and (max-width: 768px) {
    .brand {
      width: 269px;
    }
  }
}

.lang-switcher {
  .MuiButton-text {
    background: transparent;
  }
}
