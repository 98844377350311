.dialog-content-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  h1 {
    font-size: 30px;
    font-weight: 700;
  }

  .dialog-actions {
    width: 90%;
    margin-bottom: 30px;
    margin-top: 20px;

    .button-wrapper {
      width: 50%;
      display: flex;

      .close-button {
        margin-left: auto;
        margin-right: 5px;
      }

      .confirm-button {
        margin-left: 5px;
        margin-right: auto;
      }
    }
  }
}
