.APIErrorNotification{
  &.warning .MuiPaper-root{
      background-color: #ffc107;
     color:#343a40;
    }
  &.success .MuiPaper-root{
    background-color: #28a745;
  }
  &.error .MuiPaper-root{
    background-color: #dc3545;
  }
 
}

